import React from 'react'
import Footer from './Footer'
import Navbar from './Navbar'
import { Helmet } from 'react-helmet'

export default function Layout({ children }) {
    return (
        <><Helmet>
            <script type="text/javascript">
                {`(function(m,a,i,l,e,r){ m['MailerLiteObject']=e;function f(){
                var c={ a:arguments,q:[]};var r=this.push(c);return "number"!=typeof r?r:f.bind(c.q);}
                f.q=f.q||[];m[e]=m[e]||f.bind(f.q);m[e].q=m[e].q||f.q;r=a.createElement(i);
                var _=a.getElementsByTagName(i)[0];r.async=1;r.src=l+'?v'+(~~(new Date().getTime()/1000000));
                _.parentNode.insertBefore(r,_);})(window, document, 'script', 'https://static.mailerlite.com/js/universal.js', 'ml');
                
                var ml_account = ml('accounts', '1749828', 'i6t9j5r8v3', 'load');`}
            </script>
        </Helmet>
        <div>
            <Navbar />
            <div className="has-background-primary">
                { children }
            </div>
            <Footer />
            
        </div>
        </>
    )
}
