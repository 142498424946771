import React from 'react'
import { Link } from 'gatsby'
import "../mystyles.scss"

// From https://dev.to/eclecticcoding/bulma-navbar-toogle-with-react-hooks-18ek
export default function Navbar() {
    const [isActive, setisActive] = React.useState(false)
    return (
        <nav className="navbar has-background-dark pb-5" role="navigation" aria-label="main navigation">
            <div className="navbar-brand">
                <Link to="/" className="navbar-item has-text-black has-text-weight-bold is-size-4 pl-5">
                    Shackelford
                </Link>
                
                <a
                    onClick={() => {
                        setisActive(!isActive)
                    }}
                    role='button'
                    className={`navbar-burger burger has-text-dark ${isActive ? 'is-active' : ''}`}
                    aria-label='menu'
                    aria-expanded='false'
                    data-target='navbarBasicExample'
                    >
                    <span aria-hidden='true'></span>
                    <span aria-hidden='true'></span>
                    <span aria-hidden='true'></span>
                </a>
            </div>
            <div id='navbarBasicExample' className={`navbar-menu has-background-dark  ${isActive ? 'is-active' : ''}`}>
                <div className='navbar-end'>
                <div className='navbar-item'>
                    <a href='/' className='navbar-item has-text-black'>
                    Home
                    </a>
                    <Link to="/about" className="navbar-item has-text-black pr-5">About</Link>
                    <Link to="/map" className="navbar-item has-text-black pr-5">Map</Link>
                    {/* <Link to="/contact" className="navbar-item has-text-black pr-5">Contact</Link> */}
                </div>
                </div>
            </div>
        </nav>
    )
}