import { Link } from 'gatsby'
import React from 'react'

export default function Footer() {
    return (
        <footer className="has-background-dark">
            <div className="container pt-6 ">
                <div className="columns is-narrow ">
                    <div className="column has-text-centered ">
                        <Link className="has-text-black subtitle has-text-weight-bold">Shackelford Mapping</Link>
                        <p className="has-text-black is-size-6">© Copyright {new Date().getFullYear()} Jen Glaubius, The Helonaki</p>
                        <p className="has-text-black is-size-6">All rights reserved.</p>
                    </div>
                    <div className="column">
                        <ul>
                            <li><Link to="/about" className="has-text-black">About</Link></li>
                            <li><Link to="/map" className="has-text-black">Map</Link></li>
                            {/* <li><Link to="/contact" className="has-text-black">Contact</Link></li> */}
                        </ul>
                    </div>
                </div>
                <div>
                    <p className="has-text-black has-text-centered is-size-7 pt-5">Shackelford Mapping website designed by Jen @ <a className="has-text-light" href="https://www.helonaki.com">The Helonaki</a></p>
                </div>
            </div>
        </footer>
    )
}
